import { createSlice } from "@reduxjs/toolkit";
import { changePassword, checkAppInfo, checkLoginSource, checkUserWithRedirect, cleanUser, loginSingleSignOn, loginUser, loginWithGoogle, logoutUser, removeFunctionUser, resetPassword, showModal, signUpUser, verifyAccount, verifyEmailAccount, verifyRefreshSession } from "./actions";
import { COMFE_RESET_MESSAGE, firebaseErrorMessage, getFromStorage, logger, setToStorage } from "./utilities";

const initialState = () => ({
    login: {
        user: false,
        appInfo: false,
        loading: false,
        logout: false,
        reset: false,
        started: false,
        modal: false,
        deletedUser: false,
        verify: false,
        refreshedToken: false
    }
});

export const loginSlice = createSlice({
    name: "login",
    initialState: initialState().login,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(checkLoginSource.pending, (source) => {
            source.loading = true;
        });
        builder.addCase(checkLoginSource.fulfilled, (source, action) => {
            source.list = action.payload;
            source.loading = false;
        });
        builder.addCase(checkLoginSource.rejected, (source) => {
            source.loading = false;
        });

        /* Sign up user */
        builder.addCase(signUpUser.pending, (source) => {
            source.loading = true;
        });
        builder.addCase(signUpUser.fulfilled, (source, action) => {
            const { email, accessToken, uid, hashedUser, error, providerId, code } = action.payload;
            source.user = { email, accessToken, uid, hashedUser, error, providerId, code };
            setToStorage('loginSlice.userSelected', action.payload);
            source.loading = false;
        });
        builder.addCase(signUpUser.rejected, (source) => {
            source.loading = false;
        });

        /* Log-in user */
        builder.addCase(loginUser.pending, (source) => {
            source.loading = true;
        });
        builder.addCase(loginUser.fulfilled, (source, action) => {
            source.user = action.payload;
            setToStorage('loginSlice.userSelected', action.payload);
            source.loading = false;
        });
        builder.addCase(loginUser.rejected, (source) => {
            source.loading = false;
        });

        /* Log-in with google */
        builder.addCase(loginWithGoogle.pending, (source) => {
            source.loading = true;
        });
        builder.addCase(loginWithGoogle.fulfilled, (source, action) => {
            source.user = action.payload;
            logger(`loginSlice.userSelected: ${action.payload}`);
            setToStorage('loginSlice.userSelected', action.payload);
            source.user = action.payload;
            source.loading = false;
        });
        builder.addCase(loginWithGoogle.rejected, (source) => {
            source.loading = false;
        });

        /* Save the app information */
        builder.addCase(checkAppInfo.pending, (source) => {
            source.loading = true;
        });
        builder.addCase(checkAppInfo.fulfilled, (source, action) => {
            source.appInfo = action.payload;
            source.loading = false;
        });
        builder.addCase(checkAppInfo.rejected, (source) => {
            source.loading = false;
        });

        /* LOGOUT logout/logoutUser */
        builder.addCase(logoutUser.pending, (source) => {
            source.loading = true;
        });
        builder.addCase(logoutUser.fulfilled, (source, action) => {
            source.logout = action.payload;
            source.user = false;
            source.loading = false;
        });
        builder.addCase(logoutUser.rejected, (source) => {
            source.loading = false;
        });

        /* loginSingleSignOn */
        builder.addCase(loginSingleSignOn.pending, (source) => {
            source.loading = true;
        });
        builder.addCase(loginSingleSignOn.fulfilled, (source, action) => {
            source.user = action.payload;
            source.loading = false;
        });
        builder.addCase(loginSingleSignOn.rejected, (source) => {
            source.loading = false;
        });

        /* resetPassword */
        builder.addCase(resetPassword.pending, (source) => {
            source.loading = true;
        });
        builder.addCase(resetPassword.fulfilled, (source, action) => {
            source.reset = `${COMFE_RESET_MESSAGE} ${action.payload}`;
            source.loading = false;
        });
        builder.addCase(resetPassword.rejected, (source, action) => {
            const { error: { code } } = action; 
            
            logger(`resetPassword.object: ${code}`);
            const message = firebaseErrorMessage(code);
            logger(`resetPassword.message: ${message}`);

            source.user={
                error: message
            };
            source.loading = false;
        });

        /* removeFunctionUser */
        builder.addCase(removeFunctionUser.pending, (source) => {
            source.loading = true;
        });
        builder.addCase(removeFunctionUser.fulfilled, (source, action) => {
            source.deletedUser = action.payload;
            source.user = false;
            source.loading = false;
        });
        builder.addCase(removeFunctionUser.rejected, (source) => {
            source.loading = false;
        });

        //changePassword
        builder.addCase(changePassword.pending, (source) => {
            source.loading = true;
        });
        builder.addCase(changePassword.fulfilled, (source, action) => {
            source.reset = 'La contraseña ha sido actualizada exitosamente, por favor intente iniciar sesión';
            source.loading = false;
        });
        builder.addCase(changePassword.rejected, (source, action) => {
            logger(`changePassword.action:`);
            logger(action);
            source.loading = false;
        });

        // verifyAccount
        builder.addCase(verifyAccount.pending, (source) => {
            source.loading = true;
        });
        builder.addCase(verifyAccount.fulfilled, (source, action) => {
            source.verify = 'La cuenta ha sido verificada exitosamente, por favor intente iniciar sesión';
            source.loading = false;
        });
        builder.addCase(verifyAccount.rejected, (source, action) => {
            logger(`verifyAccount.action:`);
            logger(action);
            const { error: { code } } = action; 
            
            logger(`verifyAccount.object: ${code}`);

            let message = firebaseErrorMessage(code);
            logger(`verifyAccount.message: ${message}`);

            source.user= {
                error: message
            };
            source.loading = false;
        });
        
        /* Show modal */
        builder.addCase(showModal, (source, action) => {
            const started = getFromStorage('modalStarted');
            if (action.payload && !started) {
                setToStorage('modalStarted', true);
                source.modal = action.payload;
            } else if (!action.payload) {
                source.modal = action.payload;
            }
        });

        /* Clean User */
        builder.addCase(cleanUser, (source) => {
            source.user = false;
        });

        //verifyRefreshSession
        builder.addCase(verifyRefreshSession.pending, (source) => {
            source.loading = true;
        });
        builder.addCase(verifyRefreshSession.fulfilled, (source, action) => {
            logger(`source.refreshedToken:`);
            logger(action.payload);
            source.refreshedToken = action.payload?.refreshedToken;
            logger(source.refreshedToken);
            source.loading = false;
        });
        builder.addCase(verifyRefreshSession.rejected, (source, action) => {
            logger(`changePassword.action:`);
            logger(action);
            source.loading = false;
            const { error: { message } } = action; 
            source.user={
                error: `Ha ocurrido un error a la hora de refrescar la sessión: ${message}`
            };
        });

        /* checkUserWithRedirect */
        builder.addCase(checkUserWithRedirect.pending, (source) => {
            source.loading = true;
        });
        builder.addCase(checkUserWithRedirect.fulfilled, (source, action) => {
            console.log(`checkUserWithRedirect.fulfilled`);
            console.log(action.payload);
            source.user = action.payload;
            source.loading = false;
        });
        builder.addCase(checkUserWithRedirect.rejected, (source, action) => {
            console.error(`checkUserWithRedirect.rejected`);
            console.error(action);
            source.loading = false;
        });

        /* verifyEmailAccount */
        builder.addCase(verifyEmailAccount.pending, (source) => {
            source.loading = true;
        });
        builder.addCase(verifyEmailAccount.fulfilled, (source, action) => {
            source.verifyMessage = `La cuenta ${action} se encuentra registrada con Google como metodo de autenticación, por favor verifique su cuenta e intente iniciar nuevamente.`;
            source.loading = false;
        });
        builder.addCase(verifyEmailAccount.rejected, (source) => {
            source.loading = false;
        });
    },
});

export const selectLoginSource = (state) => state.login.user;
export const selectLoginLoading = (state) => state.login.loading;
export const selectApplication = (state) => state.login.appInfo;
export const selectLogout = (state) => state.login.logout;
export const selectReset = (state) => state.login.reset;
export const selectModal = (state) => state.login.modal;
export const selectDeletedUser = (state) => state.login.deletedUser;
export const selectVerifySource = (state) => state.login.verify;
export const selectRefreshedToken = (state) => state.login.refreshedToken;
export const selectVerifyMessage = (state) => state.login.verifyMessage;


export default loginSlice.reducer;