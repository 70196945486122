import { useEffect, useRef, useState } from "react";
import { styled } from '@mui/material/styles';
import { useForm } from "react-hook-form";
import styles from "./styles.module.css";
import global from "../../app.module.css";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Tooltip, { tooltipClasses }  from '@mui/material/Tooltip';

function UserForm(props) {
    const { onSubmit, submitLabel, pathname, passwordTooltip } = props;
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
    const [show, setShow] = useState(false);
    const [repeated, setRepeated] = useState(false);

    const password = useRef({});
    password.current = watch("password", "");

    const email = useRef({});
    email.current = watch("email", "");

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formSubmit = (evento) => {
        onSubmit(evento);
        reset({email: '', password: ''});
    }

    const preventCopyPaste = (e) => {
        e.preventDefault()
        alert("Copiar y pegar no está permitido!")
    }

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
        ))(({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
            },
        }));

    return ( <>
                <form onSubmit={handleSubmit(formSubmit)}>
                <div
                    className={`${styles.inputBonito} ${
                    errors.email && styles.error
                    }`}
                >
                    <label
                    className={`${styles.labelName} ${styles.textoInput} ${styles.textLeft}`}
                    >
                        <span className={styles.contentName}>Correo electrónico</span>
                    </label>
                    <input
                    type="text"
                    autoComplete="off"
                    name="email"
                    placeholder="ejemplo@gmail.com"
                    onCopy={(e) => preventCopyPaste(e)}  
                    onPaste={(e) => preventCopyPaste(e)}  
                    onCut={(e) => preventCopyPaste(e)}
                    {...register("email", {
                        required: {
                        value: true,
                        message: "Necesitas este campo",
                        },
                        pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i,
                        message: "El formato no es correcto",
                        },
                    })}
                    />
                    {errors.email && (
                    <span className={errors.email && styles.mensajeError}>
                        {errors.email.message}
                    </span>
                    )}
                </div>

                {
                    pathname ==='/register' ? 
                <div
                    className={`${styles.inputBonito} ${
                    errors.email && styles.error
                    }`}
                >
                    <label
                    className={`${styles.labelName} ${styles.textoInput} ${styles.textLeft}`}
                    >
                        <span className={styles.contentName}>Repetir correo electrónico</span>
                    </label>
                    <input
                    type="text"
                    autoComplete="off"
                    name="repeatedemail"
                    placeholder="ejemplo@gmail.com"
                    onCopy={(e) => preventCopyPaste(e)}  
                    onPaste={(e) => preventCopyPaste(e)}  
                    onCut={(e) => preventCopyPaste(e)}
                    {...register("repeatedemail", {
                        required: {
                        value: true,
                        message: "Necesitas este campo",
                        },
                        pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i,
                        message: "El formato no es correcto",
                        },
                        validate: value => value === email.current || "Las correos deben coincidir" 
                    })}
                    />
                    {errors.repeatedemail && (
                    <span className={errors.repeatedemail && styles.mensajeError}>
                        {errors.repeatedemail.message}
                    </span>
                    )}
                </div>
                : '' }

                <div
                    className={`${styles.inputBonito}  ${
                    errors.password && styles.error
                    }`}
                >
                    <LightTooltip className={styles.tooltipWrapper} title={<>{passwordTooltip}</>} arrow>
                        <label
                            className={`${styles.labelName} ${styles.textoInput} ${styles.textLeft}`}
                        >
                            <span className={styles.contentName}>Contraseña</span>
                        </label>
                    </LightTooltip>
                        
                        <input
                            type={ show ? 'text' : 'password' }
                            name="password"
                            placeholder="Contraseña"
                            onCopy={(e) => preventCopyPaste(e)}  
                            onPaste={(e) => preventCopyPaste(e)}  
                            onCut={(e) => preventCopyPaste(e)}
                            {...register("password", {
                                required: {
                                value: true,
                                message: "El campo es requerido",
                                },
                                minLength: {
                                value: 6,
                                message: "La contraseña debe tener al menos 6 caracteres",
                                },
                            })}
                        />
                        
                        { !show ? 
                            <RemoveRedEyeOutlinedIcon className={styles.showIcon} onClick={() => setShow(!show)}/> :
                            <VisibilityOffOutlinedIcon className={styles.showIcon} onClick={() => setShow(!show)}/>
                        }
                        {errors.password && (
                        <span className={errors.password && styles.mensajeError}>
                            {errors.password.message}
                        </span>
                        )}
                </div>
                {
                    pathname==='/register' ? 
                    <div
                        className={`${styles.inputBonito} ${
                        errors.password && styles.error
                        }`}
                    >
                        <LightTooltip className={styles.tooltipWrapper} title={<>{passwordTooltip}</>} arrow>
                            <label
                                className={`${styles.labelName} ${styles.textoInput} ${styles.textLeft}`}
                            >
                            <span className={styles.contentName}>Repetir Contraseña</span>
                            </label>
                        </LightTooltip>
                        {/**/}
                            <input
                                type={ repeated ? 'text' : 'password' }
                                name="repeatpassword"
                                placeholder="Repetir Contraseña"
                                onCopy={(e) => preventCopyPaste(e)}  
                                onPaste={(e) => preventCopyPaste(e)}  
                                onCut={(e) => preventCopyPaste(e)}
                                {...register("repeatpassword", {
                                    required: {
                                    value: true,
                                    message: "El campo es requerido",
                                    },
                                    minLength: {
                                    value: 6,
                                    message: "La contraseña debe tener al menos 6 caracteres",
                                    },
                                    validate: value => value === password.current || "Las contraseñas deben coincidir"                            
                                })}
                            />
                        { !repeated ? 
                            <RemoveRedEyeOutlinedIcon className={styles.showIcon} onClick={() => setRepeated(!repeated)}/> :
                            <VisibilityOffOutlinedIcon className={styles.showIcon} onClick={() => setRepeated(!repeated)}/>
                        }
                        {errors.repeatpassword && (
                        <span className={errors.repeatpassword && styles.mensajeError}>
                            {errors.repeatpassword.message}
                        </span>
                        )}
                    </div>
                    : ''
                }
                <button className={`${styles.btn} ${global.registerIcon}`}>
                    {submitLabel}
                </button>
                </form>
    </> );
}

export default UserForm;