import React from "react";
import ResetComponent from "./component";


function ResetPage(props) {
    const { urlParams } = props;

    return ( 
        <ResetComponent urlParams={urlParams} />
    );
}

export default ResetPage;