import React from "react";
import LogoutComponent from "./component";


function LogoutPage(props) {
    const { urlParams } = props;

    return ( 
        <LogoutComponent urlParams={urlParams} />
    );
}

export default LogoutPage;