import { checkAppInfo, loginSingleSignOn, loginUser, loginWithGoogle, logoutUser, removeFunctionUser } from "../../reducers/actions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import styles from "./styles.module.css";
import global from "../../app.module.css";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Typography } from "@mui/material";
import { useLoginSelector } from "../../reducers/store";
import { selectApplication, selectDeletedUser, selectLoginLoading, selectLoginSource } from "../../reducers/reducer";
import { getFromStorage, logger, removeFromStorage } from "../../reducers/utilities";
import UserForm from "../../components/UserForm/UserForm";
import { Link, useLocation } from "react-router-dom";
import logoDelegante from '../../images/logo-delegante.png';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import googleSVGIcon from '../../images/logoGoogle.svg';

function DeleteComponent(props) {
    const dispatch = useDispatch();
    const { urlParams } = props;
    const appId = urlParams.get('app_id');
    const appInformation = useLoginSelector(selectApplication);
    const userDeleted = useLoginSelector(selectDeletedUser);
    const loading = useLoginSelector(selectLoginLoading);
    const userSelected = useLoginSelector(selectLoginSource);
    const location = useLocation();
    const pathname = location.pathname;

    logger(`loginSingleSignOn.start: `);
    logger(userSelected);

    const toolTipPassword = <div id="m_pswd_info" className={styles.m_pswd_info}>
    <div>
        <span id="m_length" className="m_invalid"><CancelIcon className={styles.closeIcon}/> (Obligatorio) Longitud mínimo de 8 carácteres.</span><br />
        <span id="m_opc" className="m_invalid"> <CancelIcon className={styles.closeIcon}/> Contener al menos 3 de los siguientes 4 tipos de caracteres: </span>
        <ul>
            <li id="m_letter" className="m_invalid"> Mínimo una letra minúscula.</li>
            <li id="m_capital" className="m_invalid">Mínimo una letra mayúscula.</li>
            <li id="m_number" className="m_invalid">Mínimo un número (0-9).</li>
            <li id="m_special" className="m_invalid">Mínimo un carácter especial (!@#$%^&amp;*).</li>
        </ul>
    </div>
</div>;


    useEffect(() => {
        dispatch(checkAppInfo(appId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(logoutUser(appId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => { 
        const loginWithGoogleData = getFromStorage('loginWithGoogle');
        logger(`loginWithGoogleData`);
        logger(loginWithGoogleData);

        if (appInformation && loginWithGoogleData && userSelected.uid) {
            removeFromStorage('loginWithGoogle');
            dispatch(loginSingleSignOn({appInformation, pathname, userSelected}));
        }
    }, [dispatch, appInformation, pathname, userSelected]);

    const onSubmit = (evento) => {
        const { password, email } = evento;
        dispatch(loginUser({ email, password, appInformation }));
    };

    const onGoogleSubmit = () => {
        dispatch(loginWithGoogle({ provider: 'google' }));
    };

    const onRemoveSubmit = () => {
        logger(`loginSingleSignOn.onRemoveSubmit: `);
        dispatch(removeFunctionUser( userSelected ));
    };

    function goToCallBack(target) {
        // 👇️ using window.location.href 👇️
        window.location.href = target;
        return null;
    }

    logger(`loginSingleSignOn.submit: `);
    logger(`loginSingleSignOn.onRemoveSubmit: `);

    return ( 
        <div className={`${styles.contenedor} ${global.background}`}>
            <div className={styles.container} id="container">
                <div className={styles.loginCentrado}>
                    <div className={`${styles.loadingContainer} ${loading ? styles.show : ''}`}>
                        <p>Loading...</p>
                    </div>
                    <img className={global.logoImage} src='/logo-delagnte.png' alt="logo"/>
                    <div className={`${global.row}`}>
                        {!userSelected && <h4>Si desea eliminar la cuenta creada, por favor ingrese sus credenciales</h4> }
                    </div>
                    <>  {!userSelected || userSelected.error ? 
                        <div className='centered' id="container">
                            <div className={`${styles.loadingContainer} ${loading ? styles.show : ''}`}>
                                <p>Loading...</p>
                            </div>
                            <div className={`loginCentrado`}>
                                <div>
                                    { userSelected.error 
                                        && <Alert className={styles.alertError} severity="error">{ userSelected.error }</Alert>
                                    }      
                                </div>
                                <Typography className={styles.titleLogin}>Comprobar Credenciales del Usuario</Typography>
                                <div className={`${global.row} ${global.accordionRow}`}> 
                                    <Accordion className={`${global.noShadow}`}>
                                        <AccordionSummary 
                                            className={`${styles.btn} 
                                            ${global.personalIcon} 
                                            ${global.socialButton}  
                                            ${global.accordionContent}
                                            accordionWrapper
                                            `} aria-controls="panel1a-content" id="panel1a-header">
                                            <img className={global.comfeIcon} src={logoDelegante} width="24px" height="24px" alt="logo"/>
                                            <Typography className={styles.buttonTitle}>Cuenta Personalizada</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <UserForm onSubmit={onSubmit} submitLabel={`Iniciar Sesión`} pathname={pathname} passwordTooltip={toolTipPassword}/>
                                        </AccordionDetails>
                                        <div className={styles.center}> 
                                            <Link to='/reset' className={styles.registrarLink}>¿Olvidó su contraseña?</Link>
                                        </div>
                                    </Accordion>
                                </div>
                                <Typography className={styles.titleLogin}>Inicia sesión con Google</Typography>
                                <div className={global.row}> 
                                    <Button onClick={() => onGoogleSubmit()}  variant="contained" className={`${styles.btn} ${global.googleIcon} ${global.socialButton}`}>
                                        <img className={global.icon} src={googleSVGIcon} alt="Google Icon button"></img>
                                        Iniciar Sesión con Google
                                    </Button>
                                </div>
                            </div>
                        </div> :
                            <> 
                            <div className={`${styles.loadingContainer} ${loading ? styles.show : ''}`}>
                                <p>Loading...</p>
                            </div>
                            {
                                userDeleted ? 
                                <div className={`loginCentrado`}>
                                    <div className={styles.personalTitle} severity="warning">Usuario Eliminado Exitosamente</div>
                                    <div className={`${global.row}`}>
                                        <h4>Para crear un nuevo usuario por favor de click aqui:</h4>
                                        {
                                            appInformation ? <a className={styles.registrarLink} href={appInformation.urlRegister}>Regístrate aqui</a> :
                                            <Link to='/register' className={styles.registrarLink}> Regístrate aqui</Link>
                                        }
                                    </div>
                                </div> :
                                <div className={`loginCentrado`}>
                                    <div className={styles.personalTitle} severity="success">Usuario comprobado: {userSelected.email}</div>
                                    <div className={`${global.row}`}>
                                        <h4>¿Estás seguro que deseas remover tu usuario definitivamente?</h4>
                                    </div>
                                    <div className={`${styles.botonera}`}>
                                        <Button className={styles.comfenalcoColored} variant="contained" onClick={onRemoveSubmit}>Si</Button>
                                        <Button onClick={() => goToCallBack(`/delete?app_id=${appId}`)} variant="outlined">No</Button>
                                    </div>
                                </div>
                            }
                            </> 
                        }
                    </>
                </div>
            </div>
        </div>
    
    );
}

export default DeleteComponent;