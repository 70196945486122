import { checkAppInfo, logoutUser } from "../../reducers/actions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import styles from "./styles.module.css";
import global from "../../app.module.css";
import { Button } from "@mui/material";
import { useLoginSelector } from "../../reducers/store";
import { selectApplication, selectLogout } from "../../reducers/reducer";
import { logger } from "../../reducers/utilities";

function LogoutComponent(props) {
    const dispatch = useDispatch();
    const { urlParams } = props;
    const appId = urlParams.get('app_id');
    const appInformation = useLoginSelector(selectApplication);
    const logoutObject = useLoginSelector(selectLogout);

    useEffect(() => {
        dispatch(checkAppInfo(appId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(logoutUser(appId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (logoutObject && appInformation) {
            goToCallBack();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutObject, appInformation]);

    function goToCallBack() {
        // 👇️ using window.location.href 👇️
        logger('appInformation', 'info');
        logger(appInformation, 'info');
        // const target = `${appInformation['url_callback']}`;

        window.history.back();

        return null;
    }

    return ( 
        <div className={`${styles.contenedor} ${global.background}`}>
            <div className={styles.container} id="container">
                <div className={styles.loginCentrado}>
                    <img className={global.logoImage} src='/logo-delagnte.png' alt="logo"/>
                    <div className={`${global.row}`}>
                        <h4>La sesión del usuario actual ha sido cerrada</h4>
                    </div>
                    <div className={`${global.row}`}>
                        <Button 
                            onClick={() => goToCallBack()} 
                            variant="contained" 
                            className={`${styles.btn} ${styles.center}`}><span>Regresando...</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default LogoutComponent;