import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    GoogleAuthProvider, 
    FacebookAuthProvider,
    TwitterAuthProvider,
    OAuthProvider,
    signInWithPopup,
    setPersistence,
    browserLocalPersistence,
    signOut,
    sendPasswordResetEmail,
    getRedirectResult,
    verifyPasswordResetCode,
    applyActionCode,
    confirmPasswordReset,
    signInWithRedirect,
    sendSignInLinkToEmail,
} from "firebase/auth";

import { doc, getDoc, addDoc, collection } from "firebase/firestore";
import { auth, firestore } from "../firbase.config";
import { emptyString, firebaseErrorMessage, getCoordinates, getFromStorage, getRamdomString, logger, setToStorage } from "./utilities";

const url = process.env.REACT_APP_RICK_MORTY;
const comfenalcoApi = process.env.REACT_APP_COMFENALCO_API;
const comfenalcoEmpresaApi = process.env.REACT_APP_COMFENALCO_EMPRESA_API;
const comfenalcoRemoveApi = process.env.REACT_APP_COMFENALCO_REMOVEUSER_API;
const comfenalcoGetUserApi = process.env.REACT_APP_COMFENALCO_GET_BY_EMAIL_USER_API;
const comfenalcoGetTempTokenApi = process.env.REACT_APP_COMFENALCO_GET_TEMP_TOKEN_API;

export const checkLoginSource = createAsyncThunk(
    "login/checkLoginSource",
    async (post) => {
        const response = await axios(url);
        let source = false;

        if (response) {
            const { data } = response;
            source = data;
        }

        return source;
    }
);

export const loginWithGoogle = createAsyncThunk(
    "login/loginWithGoogle",
    async (post) => {
        const { provider, pathname } = post;
        let authProvider = false;
        let userResponse = true;

        const isSignInWithRedirect = getFromStorage('signInWithRedirect');
        logger(`loginWithGoogle.isSignInWithRedirect: ${isSignInWithRedirect}`, 'info');
        setToStorage('loginWithGoogle', true);
        
        if (pathname === '/register') {
            signOutUser();
        }

        switch (provider) {
            case 'google':
                authProvider = new GoogleAuthProvider();
                break;

            case 'twitter':
                authProvider = new TwitterAuthProvider();
                break;

            case 'facebook':
                authProvider = new FacebookAuthProvider();
                break;
        
            case 'apple':
                authProvider = new OAuthProvider('apple.com');
                break;

            default:
                authProvider = new GoogleAuthProvider();
                break;
        }

        try {
            if (pathname === '/register') {
                setToStorage('signInWithRedirect', true);
            }

            await setPersistence(auth, browserLocalPersistence);
            logger(`loginWithGoogle.popupWindow: `);
            userResponse = await signInWithPopup(auth, authProvider);  
            window?.opener?.postMessage('TEST_JVERA_001: window?.opener', "*");
            window?.postMessage('TEST_JVERA_001: window', "*");

            userResponse = { 
                ...userResponse.user, 
                proactiveRefresh: false, 
                auth: false, 
                stsTokenManager: false,
                metadata: false,
            };

            window?.ReactNativeWebView?.postMessage(JSON.stringify(userResponse));
            logger(`************ loginWithGoogle.postMessage.: **************`);

        } catch (error) {
            logger(`loginWithGoogle.error: ${error.message}`, 'error');
            logger(`loginWithGoogle.code: ${error.code}`, 'error');
            const message = firebaseErrorMessage(error.code);
            return { error: message };
        }

        return userResponse;
    }
);

export const loginWithRedirect = createAsyncThunk(
    "login/loginWithRedirect",
    async (post) => {
        const { provider, pathname } = post;
        let authProvider = false;
        let userResponse = true;

        const isSignInWithRedirect = getFromStorage('signInWithRedirect');
        logger(`loginWithRedirect.isSignInWithRedirect: ${isSignInWithRedirect}`, 'info');
        setToStorage('loginWithRedirect', true);
        
        if (pathname === '/register') {
            signOutUser();
        }

        switch (provider) {
            case 'google':
                authProvider = new GoogleAuthProvider();
                break;

            case 'twitter':
                authProvider = new TwitterAuthProvider();
                break;

            case 'facebook':
                authProvider = new FacebookAuthProvider();
                break;
        
            case 'apple':
                authProvider = new OAuthProvider('apple.com');
                break;

            default:
                authProvider = new GoogleAuthProvider();
                break;
        }

        try {
            if (pathname === '/register') {
                setToStorage('signInWithRedirect', true);
            }

            await setPersistence(auth, browserLocalPersistence);
            logger(`loginWithGoogle.popupWindow: `);
            userResponse = await signInWithRedirect(auth, authProvider);  
            logger(`loginWithGoogle.userResponse: ${userResponse}`);
            window?.opener?.postMessage('TEST_JVERA_001: window?.opener', "*");
            window?.postMessage('TEST_JVERA_001: window', "*");

            userResponse = { 
                ...userResponse.user, 
                proactiveRefresh: false, 
                auth: false, 
                stsTokenManager: false,
                metadata: false,
            };

            window?.ReactNativeWebView?.postMessage(JSON.stringify(userResponse));
            logger(`************ loginWithGoogle.postMessage.: **************`);

        } catch (error) {
            logger(`loginWithGoogle.error: ${error.message}`, 'error');
            logger(`loginWithGoogle.code: ${error.code}`, 'error');
            const message = firebaseErrorMessage(error.code);
            return { error: message };
        }

        return userResponse;
    }
);

export const loginSingleSignOn = createAsyncThunk(
    "login/SingleSignOn",
    async (post) => {
            let userRedirectResponse = false;
            let popUpUser = false;
            
            logger(`loginSingleSignOn.post.userSelected`);
            logger(post.userSelected);

            if (post.userSelected && post.userSelected.uid && !post.userSelected.error ) {
                popUpUser = post.userSelected;
            }

            auth.onAuthStateChanged(function(user) {
                if (user) {
                    logger(`SingleSignOn.onAuthStateChanged.full!`);
                    logger(user);
                } else {
                    logger(`SingleSignOn.onAuthStateChanged.empty!`, 'warning');
                }
            });

            try {
                userRedirectResponse = await getRedirectResult(auth);
                logger(`loginWithGoogle.getRedirectResult:`);
                logger(userRedirectResponse);
            } catch (error) {
                logger(`loginSingleSignOn.error: ${error.message}`, 'error');
                logger(`loginSingleSignOn.code: ${error.code}`, 'error');
                const message = firebaseErrorMessage(error.code);
                return { error: message };
            }

            const isNew = userRedirectResponse?._tokenResponse?.isNewUser;

            logger(`loginWithGoogle.userRedirectResponse:`);
            logger(auth.currentUser);
            logger(`loginWithGoogle.isNew:`);
            logger(isNew);
            const user = popUpUser || auth.currentUser || userRedirectResponse.user;

            if (user) {
                const { email, accessToken, uid, displayName, providerData } = user;
                const { appInformation, numDoc, tipoDoc, tipoPersona, pathname } = post;
                logger(`loginSingleSignOn.user: ${user}`);
    
                let providerId = 'google-auth';
                let providerDisplayName = '';
                let providerEmail = '';
                let providerPhoneNumber = '';
                let providerPhotoURL = '';
                let providerUID = '';
        
                if (providerData && providerData.length > 0) {
                    providerDisplayName = emptyString(providerData[0].displayName);
                    providerEmail = emptyString(providerData[0].email);
                    providerPhoneNumber =  emptyString(providerData[0].phoneNumber);
                    providerUID =  emptyString(providerData[0].uid);
                    providerPhotoURL = emptyString(providerData[0].photoURL);
                    providerId = emptyString(providerData[0].providerId);
                }
        
                let hashedUser = false;
                let actualizarData = false;
        
                const userSesion = {
                    documento: emptyString(numDoc),
                    tipoDoc: emptyString(tipoDoc),
                    tipoPersona: emptyString(tipoPersona),
                    displayName: emptyString(displayName),
                    userEmail: email,
                    providerId: emptyString(providerId),
                    providerDisplayName,
                    providerEmail,
                    providerPhoneNumber,
                    providerUID,
                    providerPhotoURL,
                    uid: uid,
                    aplicacion: appInformation.app_id,
                    isNewUser: isNew ? isNew : false,
                }
                ///aplicaciones/comfenalco.react.app/sesiones/
                const collectionRef = collection(firestore, `/usuarios`);
        
                if (appInformation) {
                    let comfenalcoResponse = false;
                    let customSessionId = getRamdomString(45);
                    logger(`getRamdomString.customSessionId: ${customSessionId}`);
                    
                    try {
                        // const position = await getCoordinates(); 
                        // const { accuracy, latitude, longitude } = position.coords;
                        // const browserId = navigator.userAgent.toLowerCase().replace(/\s+/g, "");
                        // customSessionId = `${accuracy}-${latitude}-${longitude}-${browserId}`;
                        logger(`custom_session_id: ${customSessionId}`);
                    } catch(e) {
                        logger(`Error Information`, 'error');
                        logger(e, 'error');
                    }

                    try {
                        
                        comfenalcoResponse = await axios.post(
                            comfenalcoApi, 
                            {app_id: appInformation, email, token: uid, accessToken, tipoDoc, numDoc, customSessionId}, 
                            {headers: {'Authorization': `Bearer ${accessToken}`} }
                        );

                    } catch(e) {
                        logger(`comfenalcoResponse.error: ${e.message}`, 'error');
                        logger(e, 'error');
                        const { response: { data: {message} } } = e;
                        
                        return { error: message };
                    }

                    logger('============ COMFENALCO RESPONSE ===========');
                    logger(comfenalcoResponse);

                    if (typeof comfenalcoResponse.data === 'string') {
                        hashedUser = comfenalcoResponse.data;
                        actualizarData = false;
                    } else {
                        hashedUser = comfenalcoResponse.data.tokenId;
                        actualizarData = comfenalcoResponse.data.actualizarUsuario;
                    }
                }

                if (pathname === '/register') {
                    userSesion.fechaCreacion = new Date();
                    setToStorage('signInWithRedirect', false);

                    await addDoc(collectionRef, userSesion);
                }

                return { email, accessToken, uid, hashedUser, displayName, providerId, actualizarData }
            }
        
        return false;
    });

export const signUpUser = createAsyncThunk(
    "login/signUpUser",
    async (post) => {
        const { email, password, numDoc, tipoDoc, tipoPersona } = post;

        let source = {
            user: 'demo'
        };

        try {
            const response = await createUserWithEmailAndPassword(auth, email, password);
            logger('signUpUser.user.response');
            logger(response);
            // Save USER
            const userSesion = {
                documento: numDoc,
                tipoDoc: tipoDoc,
                tipoPersona: tipoPersona,
                providerDisplayName: '',
                providerEmail: '',
                providerPhoneNumber: '',
                providerUID: '',
                providerPhotoURL: '',
                providerId: ''
            }
            ///aplicaciones/comfenalco.react.app/sesiones/
            const collectionRef = collection(firestore, `/usuarios`);

            if (response) {
                const { user: { email, accessToken, uid, displayName, providerId } } = response;

                userSesion.uid=uid;
                userSesion.displayName=displayName ? displayName : '';
                userSesion.providerId=providerId;
                userSesion.userEmail=email;
                userSesion.providerEmail=email;
                userSesion.fechaCreacion = new Date();
                
                await addDoc(collectionRef, userSesion);
                // const comfenalcoResponse = await axios.post(comfenalcoApi, { app_id: appInformation, email, token: uid, accessToken, tipoDoc, numDoc });
                // const hashedUser = comfenalcoResponse.data;

                source = { email, accessToken, uid, hashedUser: uid, displayName, providerId };
            }
        } catch(e) {
            logger(e.message, 'error');
            const errorMessage = firebaseErrorMessage(e.code);
            source.error = errorMessage;
            source.code = e.code;
        }

        return source;
    }
);

export const loginUser = createAsyncThunk(
    "login/loginUser",
    async (post) => {
        const { email, password, appInformation, empresas } = post;
        let source = {
            user: 'demo'
        };

        logger(`========== COMFENALCO post ==========`);
        logger(post);

        try {
            await setPersistence(auth, browserLocalPersistence);
            const response = await signInWithEmailAndPassword(auth, email, password);
            if (response) {
                const { user: { email, accessToken, uid, displayName, providerId } } = response;

                let comfenalcoResponse = false;
                let customSessionId = getRamdomString(45);
                logger(`.customSessionId: ${customSessionId}`);
                
                try {
                    // const position = await getCoordinates(); 
                    // const { accuracy, latitude, longitude } = position.coords;
                    // const browserId = navigator.userAgent.toLowerCase().replace(/\s+/g, "");
                    // customSessionId = `${accuracy}-${latitude}-${longitude}-${browserId}`;
                    logger(`getRamdomString.custom_session_id: ${customSessionId}`);
                } catch(e) {
                    logger(`getRamdomString.ERROR: ${e.message}`, 'error');
                }

                try {
                    logger(`========== COMFENALCO EMPRESAS ==========`);
                    logger(comfenalcoEmpresaApi);

                    let authIndex = Math.ceil(Math.random() * 8);
                    const authIds = [
                        'auth0|5f04dce74fa1c60019115d74',
                        'auth0|5f0528d492882c001361338e',
                        'auth0|60d4d692cfa71500715278ad',
                        'auth0|60d4e4a9d4663a0069e9c956',
                        'auth0|5f04da714fa1c600191159b8',
                        'auth0|60d4e4a9d4663a0069e9c956',
                        'auth0|601872bb5b42670070656d45',
                        'auth0|5d91fbfad7497b0dd44709cc',
                    ];

                    logger(`========== USER ID ==========`);
                    logger(uid);
                    logger(`========== AUTH ID ==========`);
                    logger(authIds[authIndex]);

                    if (empresas) {
                        comfenalcoResponse = await axios.post(
                            comfenalcoEmpresaApi,
                            {app_id: appInformation, email, user_id: uid, accessToken, customSessionId},                         
                            {headers: {'Authorization': `Bearer ${accessToken}`} });
                    } else {
                        comfenalcoResponse = await axios.post(
                            comfenalcoApi, 
                            {app_id: appInformation, email, token: uid, accessToken, customSessionId},                         
                            {headers: {'Authorization': `Bearer ${accessToken}`} });
                    }

                    logger(`========== COMFENALCO RESPONSE ==========`);
                    logger(comfenalcoResponse);

                } catch(e) {
                    logger(`comfenalcoResponse.error: ${e.message}`, 'error');
                    const {response: { data: {message} } } = e;
                    source.error = message;
                    source.code = message;

                    return source;
                }

                let hashedUser = false;
                let actualizarData = false;

                if (typeof comfenalcoResponse.data === 'string') {
                    hashedUser = comfenalcoResponse.data;
                } else {
                    hashedUser = comfenalcoResponse.data.tokenId;
                    actualizarData = comfenalcoResponse.data.actualizarUsuario;
                }
                source = { email, accessToken, uid, hashedUser, displayName, providerId, actualizarData  };
            }
        } catch(e) {
            logger(`comfenalcoResponse.error.validate: ${e.code}`, 'error');
            let noPassword = false;
            if (e.code === "auth/wrong-password" || e.code === "auth/user-not-found") {
                const accessToken = await axios.post(
                    comfenalcoGetTempTokenApi,
                    { 
                        email: process.env.REACT_APP_COMFENALCO_DEFAULT_ADMIN_EMAIL,
                        app_id: process.env.REACT_APP_COMFENALCO_DEFAULT_ADMIN_APP_ID,
                        uid: process.env.REACT_APP_COMFENALCO_DEFAULT_ADMIN_UID
                    }
                );                        

                const apiUser = await axios.post(
                    comfenalcoGetUserApi,
                    { email },                         
                    {headers: {'Authorization': `Bearer ${accessToken?.data?.adminToken}`} }
                );

                if (apiUser?.data?.providerData) {
                    for (let i = 0; i < apiUser.data.providerData.length; i++) {
                        if (apiUser.data.providerData[i].providerId !== 'password') {
                            noPassword = apiUser.data.providerData[i].providerId;
                        }
                    }
                }
            }

            logger(`comfenalcoResponse.error.noPassword.ext:`);
            logger(noPassword);

            if (noPassword) { 
                source.error = `El metodo de autenticación para el usuario ${email} es ${noPassword.toUpperCase()}, por favor inicie sesión con el metodo correcto.`;
                source.code = e.code;
            } else {
                const errorMessage = firebaseErrorMessage(e.code);
                source.error = errorMessage;
                source.code = e.code;
            }
        }
        return source;
    }
);

export const checkAppInfo = createAsyncThunk(
    "login/checkAppInfo",
    async (post) => {
        const docRef = doc(firestore, "aplicaciones", post);
        const actUsuarioRef = doc(firestore, "config", "ACTUALIZACION_DATOS");

        let docSnap = false;
        let docActUsuario = false;
        
        try {
            docSnap = await getDoc(docRef);
        } catch(error) {
            console.error(error);
        }

        try {
            docActUsuario = await getDoc(actUsuarioRef);
        } catch(error) {
            console.error(error);
        }
        
        let source = false
        if (docSnap && docSnap.exists()) {
            source = docSnap.data();

            if (docActUsuario && docActUsuario.exists()) {
                source.actUsuario = docActUsuario.data();

                console.log('source.actUsuario');
                console.log(source.actUsuario);
            }
        } else {
            // doc.data() will be undefined in this case
            logger(`No such document!`, 'warning');
        }
        
        return source;
    }
);

export const checkUserWithRedirect = createAsyncThunk(
    "login/checkUserWithRedirect",
    async (post) => {
        console.log('=============== USER checkUserWithRedirect RESPONSE ================');
        let user = false;
        try {
            const { user: userData } = await getRedirectResult(auth);
            user = userData;
        } catch (e) {
            console.error("checkUserWithRedirect.error");
            console.error(e.message);
            throw e;
        }

        console.log('checkUserWithRedirect.userResponse--1', user);

        if (user) {
            const { email, accessToken, uid, displayName, providerData } = user;
            const { appInformation, numDoc, tipoDoc, tipoPersona, pathname } = post;
            logger(`loginSingleSignOn.user: ${user}`);

            let providerId = 'google-auth';
            let providerDisplayName = '';
            let providerEmail = '';
            let providerPhoneNumber = '';
            let providerPhotoURL = '';
            let providerUID = '';
    
            if (providerData && providerData.length > 0) {
                providerDisplayName = emptyString(providerData[0].displayName);
                providerEmail = emptyString(providerData[0].email);
                providerPhoneNumber =  emptyString(providerData[0].phoneNumber);
                providerUID =  emptyString(providerData[0].uid);
                providerPhotoURL = emptyString(providerData[0].photoURL);
                providerId = emptyString(providerData[0].providerId);
            }
    
            let hashedUser = false;
            let actualizarData = false;
    
            const userSesion = {
                documento: emptyString(numDoc),
                tipoDoc: emptyString(tipoDoc),
                tipoPersona: emptyString(tipoPersona),
                displayName: emptyString(displayName),
                userEmail: email,
                providerId: emptyString(providerId),
                providerDisplayName,
                providerEmail,
                providerPhoneNumber,
                providerUID,
                providerPhotoURL,
                uid: uid,
                aplicacion: appInformation.app_id,
            }
            ///aplicaciones/comfenalco.react.app/sesiones/
            const collectionRef = collection(firestore, `/usuarios`);
    
            if (appInformation) {
                let comfenalcoResponse = false;
                let customSessionId = getRamdomString(45);
                logger(`getRamdomString.customSessionId: ${customSessionId}`);
                
                try {
                    // const position = await getCoordinates(); 
                    // const { accuracy, latitude, longitude } = position.coords;
                    // const browserId = navigator.userAgent.toLowerCase().replace(/\s+/g, "");
                    // customSessionId = `${accuracy}-${latitude}-${longitude}-${browserId}`;
                    logger(`custom_session_id: ${customSessionId}`);
                } catch(e) {
                    logger(`Error Information`, 'error');
                    logger(e, 'error');
                }

                try {
                    
                    comfenalcoResponse = await axios.post(
                        comfenalcoApi, 
                        {app_id: appInformation, email, token: uid, accessToken, tipoDoc, numDoc, customSessionId}, 
                        {headers: {'Authorization': `Bearer ${accessToken}`} }
                    );

                } catch(e) {
                    logger(`comfenalcoResponse.error: ${e.message}`, 'error');
                    logger(e, 'error');
                    const { response: { data: {message} } } = e;
                    
                    return { error: message };
                }

                logger('============ COMFENALCO RESPONSE ===========');
                logger(comfenalcoResponse);

                if (typeof comfenalcoResponse.data === 'string') {
                    hashedUser = comfenalcoResponse.data;
                    actualizarData = false;
                } else {
                    hashedUser = comfenalcoResponse.data.tokenId;
                    actualizarData = comfenalcoResponse.data.actualizarUsuario;
                }
            }

            if (pathname === '/register') {
                userSesion.fechaCreacion = new Date();
                setToStorage('signInWithRedirect', false);

                await addDoc(collectionRef, userSesion);
            }

            return { email, accessToken, uid, hashedUser, displayName, providerId, actualizarData }
        }
        
        return false;
    }
);

export const removeFunctionUser = createAsyncThunk(
    "login/removeFunctionUser",
    async (post) => {
        const { uid, accessToken, email } = post;
        let source = false;

        logger(`comfenalcoResponse.removeFunctionUser`, 'info');
        logger(post, 'info');

        try {
            signOutUser();
            await axios.post(
                comfenalcoRemoveApi, 
                { userId: uid },                         
                { headers: {'Authorization': `Bearer ${accessToken}`} });

            source = { uid, email };
            return source;

        } catch(e) {
            logger(`comfenalcoResponse.error: ${e.message}`, 'error');
            const {response: { data: {message} } } = e;
            source.error = message;
            source.code = message;

            return source;
        }
    }
);

export const logoutUser = createAsyncThunk(
    "logout/logoutUser",
    async (post) => {       
        try {
            await signOut(auth);    
            logger(`signOutUser!`, 'info'); 
        } catch (e) {
            logger(`signOutUser! ${e.message}`, 'error');
        }
        
        auth.onAuthStateChanged(function(user) {
            if (user) {
                signOut(auth);
            } else {
                logger(`logoutUser.onAuthStateChanged.empty!`, 'warning');
            }
        });

        return { message: 'singout' }
});

export const signOutUser = createAsyncThunk(
    "logout/signOutUser",
    async (post) => {  
        logger(`signOutUser!`, 'warning');

        try {
            await signOut(auth);    
            logger(`signOutUser!`, 'info'); 
        } catch (e) {
            logger(`signOutUser! ${e.message}`, 'error');
        }
        // auth/account-exists-with-different-credential
        return { message: 'singout' }
});

const maskEmail = (email = '') => {
    const [name, domain] = email.split('@');
    const { length: len } = domain;
    const maskedName = name[0]+name[1] + '******';
    const maskedDomain = '******' + domain[len - 2]+domain[len - 1];
    const maskedEmail = maskedName + '@' + maskedDomain;
    return maskedEmail;
};

export const resetPassword = createAsyncThunk(
    "login/resetPassword",
    async (post) => {
        const maskedMail = maskEmail(post);
        await sendPasswordResetEmail(auth, post);

        return maskedMail;
});

export const changePassword = createAsyncThunk(
    "login/changePassword",
    async (post) => {
        logger(`changePassword!`, 'info'); 
        logger(post, 'info');
        const { oobCode, password } = post;

        await verifyPasswordResetCode(auth, oobCode);
        const confirmResponse = await confirmPasswordReset(auth, oobCode, password);
        logger(`confirmResponse! ${confirmResponse}`, 'info');
        //await sendPasswordResetEmail(auth, post);

        return oobCode;
});

export const verifyAccount = createAsyncThunk(
    "login/verifyAccount",
    async (post) => {
        const { oobCode } = post;
        await applyActionCode(auth, oobCode);
        return 'Cuenta verificada exitosamente';
});

export const verifyRefreshSession = createAsyncThunk(
    "login/verifyRefreshSession",
    async (post) => {
        const { accessToken, uid, token } = post;
        const response = await axios.post(
            process.env.REACT_APP_COMFENALCO_VERIFY_SESSION_URL, 
            { accessToken, uid, token },                         
            { headers: {'Authorization': `Bearer ${accessToken}`} });
        return response.data;
});

export const verifyEmailAccount = createAsyncThunk(
    "login/verifyEmailAccount",
    async (post) => {
        const { email } = post;
        await sendSignInLinkToEmail(auth, email, {
            url: process.env.REACT_APP_COMFENALCO_ACTION_URL_DEFAULT,
            handleCodeInApp: true,
        });
        return email;
});

export const showModal = createAction("login/showModal");
export const cleanUser = createAction("login/cleanUser");