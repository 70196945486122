import { checkAppInfo, resetPassword, changePassword, verifyAccount } from "../../reducers/actions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import styles from "./styles.module.css";
import global from "../../app.module.css";
import { Alert } from "@mui/material";
import { useLoginSelector } from "../../reducers/store";
import { selectLoginLoading, selectLoginSource, selectReset, selectVerifySource } from "../../reducers/reducer";
import ResetForm from "../../components/UserForm/ResetForm";
import { logger } from "../../reducers/utilities";

function ResetComponent(props) {
    const dispatch = useDispatch();
    const { urlParams } = props;
    const appId = urlParams.get('app_id');
    const mode = urlParams.get('mode');
    const oobCode = urlParams.get('oobCode');
    const urlEmail = urlParams.get('oobCode');
    const isReset = useLoginSelector(selectReset);
    const loading = useLoginSelector(selectLoginLoading);
    const userSelected = useLoginSelector(selectLoginSource);
    const isVerified = useLoginSelector(selectVerifySource);

    useEffect(() => {
        dispatch(checkAppInfo(appId));
        logger(`formSubmit.oobCode`);
        logger(oobCode);
        logger(`formSubmit.urlEmail`);
        logger(urlEmail);
        // eslint-disable-next-line react-hooks/exhaustive-deps

        if (mode === 'verifyEmail') {
            dispatch(verifyAccount({oobCode }));
        }
    }, []);

    const submitPassword = (evento) => {
        const { email } = evento;
        let tempEmail = urlEmail ? urlEmail : email;

        logger(`submitPassword.tempEmail ${tempEmail}`);
        logger(`submitPassword.email ${email}`);
        dispatch(resetPassword(tempEmail));
    };

    const submitChangePassword = (evento) => {
        const { password } = evento;
        logger(`submitChangePassword.evento ${evento}`);
        logger(`submitChangePassword.password ${password}`);
        dispatch(changePassword({ ...evento, oobCode }));
    };

    return ( 
        <div className={`${styles.contenedor} ${global.background}`}>
            <div className={styles.container} id="container">
                <div className={`${styles.loadingContainer} ${loading ? styles.show : ''}`}>
                    <p>Loading...</p>
                </div>
                <div className={styles.loginCentrado}>
                    <img className={global.logoImage} src='/logo-delagnte.png' alt="logo"/>
                    {
                        userSelected && userSelected.error ? 
                        <Alert severity="error">{ userSelected.error }</Alert> : ''
                    }
                    {
                        mode === 'verifyEmail' ? isVerified ? <Alert severity="success"><h3>{ isVerified }</h3></Alert> : 
                        <h4>{loading ? 'Verificando la cuenta, por favor espere...' : ''}</h4> : 
                        isReset ?
                        <Alert severity="info">{ isReset }</Alert> :
                        <div className={styles.resetContainer}>
                            <div className={`${global.row}`}>
                                <h3>Ingrese la contraseña registrada en los servicios de comfenalco.</h3>
                            </div>
                            <div className={`${global.row}`}>
                                <ResetForm mode={mode} onSubmit={submitPassword} onChangePassword={submitChangePassword} submitLabel={`Cambiar Password`} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    
    );
}

export default ResetComponent;