import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkHomeSource } from "./reducers/actions";

function HomeComponent(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkHomeSource());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return ( <h1>Home</h1> );
}

export default HomeComponent;