import { configureStore } from '@reduxjs/toolkit';
import { useSelector } from "react-redux";
import loginReducer from "./reducer";

export const store = configureStore({
    reducer: {
        login: loginReducer,
    }
})

export const useLoginSelector = useSelector;