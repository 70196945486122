import { configureStore } from '@reduxjs/toolkit';
import { useSelector } from "react-redux";
import homeReducer from "./reducer";

export const store = configureStore({
    reducer: {
        home: homeReducer
    }
})

export const useHomeSelector = useSelector;