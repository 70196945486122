import { createSlice } from "@reduxjs/toolkit";
import { checkHomeSource } from "./actions";

const initialState = () => ({
    home: {
        list: [],
        loading: false,
    }
});

export const homeSlice = createSlice({
    name: "home",
    initialState: initialState().home,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(checkHomeSource.pending, (source) => {
            source.loading = true;
        });
        builder.addCase(checkHomeSource.fulfilled, (source, action) => {
            source.list = action.payload;
            source.loading = false;
        });
        builder.addCase(checkHomeSource.rejected, (source) => {
            source.loading = false;
        });
    },
});

export const selectHomeSource = (state) => state.home.list;
export const selectHomeLoading = (state) => state.home.loading;

export default homeSlice.reducer;