import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import UserForm from "../../components/UserForm/UserForm";
import styles from "./styles.module.css";
import "./styles.css";
import global from "../../app.module.css";
import { Link, useLocation } from "react-router-dom";
import { checkAppInfo, checkUserWithRedirect, cleanUser, loginSingleSignOn, loginUser, loginWithGoogle, loginWithRedirect, removeFunctionUser, showModal, verifyRefreshSession } from "../../reducers/actions";
import { useLoginSelector } from "../../reducers/store";
import { selectApplication, selectLoginLoading, selectLoginSource, selectModal, selectRefreshedToken } from "../../reducers/reducer";
import Alert from '@mui/material/Alert';
import { Accordion, AccordionDetails, AccordionSummary, Button, LinearProgress, Tab, Tabs, Typography } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import { COMFE_ERROR_CODE, getFromStorage, isNonEmptyDigit, logger, setToStorage } from "../../reducers/utilities";
import CancelIcon from '@mui/icons-material/CancelOutlined';
import googleSVGIcon from '../../images/logoGoogle.svg';
import logoDelegante from '../../images/logo-delegante.png';
import CustomModal from "../../components/CustomModal/CustomModal";
import Countdown, { zeroPad } from 'react-countdown';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

const renderCountdown = ({ seconds }) => (
    <span className={styles.timerCountdown}>
        {zeroPad(seconds)}
    </span>
);

const getUrlParam = (urls, index) => {
    logger(`getUrlParam.urls ${index} ${!isNonEmptyDigit(index)}`);
    if (!isNonEmptyDigit(index)) return false;
    if (!urls) return false;
    if (urls.length < 1) return false; 
    if (urls.length === 1) return urls[0];
    return urls[+index];
}

function LoginComponent(props) {
    const { urlParams } = props;
    const appId = urlParams.get('app_id');
    const urlCallbackParam = urlParams.get('indexUrlCallback');
    const urlMovil = urlParams.get('urlMovil');
    const sessionID = urlParams.get('sessionID');
    const tipoEmpresa = urlParams.get('tipo');
    const forzarActualizacion = urlParams.get('forzarActualizacion');
    const location = useLocation();
    const pathname = location.pathname;
    const dispatch = useDispatch();
    const appInformation = useLoginSelector(selectApplication);
    const loading = useLoginSelector(selectLoginLoading);
    const userSelected = useLoginSelector(selectLoginSource);
    const isModal = useLoginSelector(selectModal);
    const refreshedToken = useLoginSelector(selectRefreshedToken);
    const [ personas, setPersonas ] = useState(tipoEmpresa === 'E' ? 1 : 0);
    const [ createuser, setCreateuser ] = useState(false);
    const [ redirect, setRedirect ] = useState(false);
    const [ actuser, setActuser ] = useState(false);

    logger(`LoginComponent.app_id 1001 ${appId}`);

    const toolTipPassword = <div id="m_pswd_info" className={styles.m_pswd_info}>
                    <div>
                        <span id="m_length" className="m_invalid"><CancelIcon className={styles.closeIcon}/> (Obligatorio) Longitud mínimo de 8 carácteres.</span><br />
                        <span id="m_opc" className="m_invalid"> <CancelIcon className={styles.closeIcon}/> Contener al menos 3 de los siguientes 4 tipos de caracteres: </span>
                        <ul>
                            <li id="m_letter" className="m_invalid"> Mínimo una letra minúscula.</li>
                            <li id="m_capital" className="m_invalid">Mínimo una letra mayúscula.</li>
                            <li id="m_number" className="m_invalid">Mínimo un número (0-9).</li>
                            <li id="m_special" className="m_invalid">Mínimo un carácter especial (!@#$%^&amp;*).</li>
                        </ul>
                    </div>
                </div>;

    useEffect(() => {
        if (sessionID) {
            setToStorage('sessionID', sessionID);
        }
        dispatch(showModal(true));
        dispatch(checkAppInfo(appId));
    }, []);

    useEffect(() => {
        if (appInformation && appInformation.signWithRedirect) {
            dispatch(checkUserWithRedirect({appInformation, pathname, userSelected}));  
        } 
    }, [appInformation, pathname, userSelected]);

    useEffect(() => { 
        if (!actuser && !createuser && !redirect && appInformation && userSelected && userSelected.uid) {
            dispatch(loginSingleSignOn({appInformation, pathname, userSelected}));
        }
    }, [dispatch, appInformation, pathname, userSelected, createuser]);

    useEffect(() => {
        logger(`useEffect.REACT_APP_COMFENALCO_AUTHDOMAIN ${process.env.REACT_APP_COMFENALCO_AUTHDOMAIN}`);
        logger(userSelected);

        if (userSelected && 
            userSelected.error && 
            userSelected.code === 'auth/user-not-found') {
            logger(`=================== useEffect.user-not-found =================`);
            logger(userSelected);
            dispatch(cleanUser());
            setRedirect({message: `${userSelected.error}. ¿Desea registrarse?`, url: appInformation?.urlRegister});
            return;
        }

        logger(`=================== useEffect.userSelected =================`);
        logger(userSelected);
        logger(`=================== useEffect.createuser =================`);
        logger(createuser);

        logger(`=================== useEffect.actuser =================`);
        logger(actuser);

        logger(`=================== userSelected.hashedUser =================`);
        logger(userSelected.hashedUser);

        if (!actuser && !redirect && !createuser && userSelected && userSelected.hashedUser && userSelected.uid && appInformation) { 
            logger(`=================== userSelected.alternativeUrlCallback =================`);
            let alternativeUrlCallback = appInformation['url_callback'].includes('?') ? `${appInformation['url_callback']}&` : `${appInformation['url_callback']}?`;

            logger(`=================== userSelected.selectedCallback =================`);
            logger(alternativeUrlCallback);
            let target = false;
            const { email, hashedUser, providerId, accessToken, actualizarData } = userSelected;
            const storageSessionId = getFromStorage('sessionID');
            logger(`=================== useEffect.94 =================`);
            logger(providerId)
            logger(actualizarData);
            logger(hashedUser);

            if (hashedUser === COMFE_ERROR_CODE || userSelected.hashedUser === 'auth/timeout-comfe-info') {
                logger(`=================== useEffect.COMFE_ERROR_CODE =================`);
                target = appInformation.urlRegister;
                if (userSelected.uid) {
                    dispatch(removeFunctionUser( userSelected ));
                }
                setRedirect({message: `El usuario ${email?.toUpperCase()} no se encuentra activo en los servicios de comfenalco. ¿Desea registrarse?`, url: target});
                return;
            } else if (refreshedToken || forzarActualizacion === '1') {
                logger(`=================== useEffect.refreshedToken HERE HERE JVERA =================`);
                let urlredirectActualizar = `${alternativeUrlCallback}user=${email}&token=${hashedUser}&providerId=${providerId}&accessToken=${accessToken}${storageSessionId ? `&sessionID=${storageSessionId}` : ''}`;
                logger(urlredirectActualizar);
                urlredirectActualizar = base64_encode(forzarActualizacion === '1' ? appInformation.actUsuario.url_forzar : urlredirectActualizar);
                // target =`${appInformation.actUsuario ? appInformation.actUsuario.url : process.env.REACT_APP_COMFENALCO_ACTUALIZAR_DATOS_URL}${appId}&sessionId=${hashedUser}&accessToken=${refreshedToken}&userType=${actualizarData.userType}&TIPOIDENTIFICACION=${actualizarData.abreviatura}&IDENTIFICACION=${actualizarData.identificacion}&CALLBACK=${urlredirectActualizar}`;
                const urlActualizar = appInformation.actUsuario ? appInformation.actUsuario.url : process.env.REACT_APP_COMFENALCO_ACTUALIZAR_DATOS_URL; 
                target = `${urlActualizar}${appId}&token=${hashedUser}&accessToken=${accessToken}&firebaseSessionId=${userSelected.uid}&userType=${actualizarData.userType}&TIPOIDENTIFICACION=${actualizarData.abreviatura}&IDENTIFICACION=${actualizarData.identificacion}&CALLBACK=${urlredirectActualizar}&fromLogin=true&providerId=${providerId}&forzarActualizacion=${forzarActualizacion}`;

                logger(`=================== useEffect.actualizarData =================`);
                logger(urlActualizar);

                if (forzarActualizacion === '1') {
                    setCreateuser("Estamos cargando la información de su usuario, por favor espere un momento...");
    
                    setTimeout(() => {
                        goToCallBack(target);
                    }, 2000);
                } else if (actualizarData.indActDatos === "3") {
                    setCreateuser("Es necesario actualizar sus datos de contacto, será redirigido a la página de actualización en: ");
    
                    setTimeout(() => {
                        goToCallBack(target);
                    }, 10000);
                } else if (actualizarData.indActDatos === "2") {
                    setActuser({
                        urlCallback: `${alternativeUrlCallback}user=${email}&token=${hashedUser}&providerId=${providerId}&accessToken=${accessToken}${storageSessionId ? `&sessionID=${storageSessionId}` : ''}`,
                        urlUpdate: target
                    });
                }

                return;
            } else if (
                appInformation && 
                appInformation.actUsuario && 
                appInformation.actUsuario.active && 
                appInformation.isUpdateUser &&
                actualizarData && actualizarData.indActDatos === "3" ) {
                logger(`=================== useEffect.actUsuario =================`);   
                handlerVerifySession({accessToken, uid: userSelected.uid, token: hashedUser});
                return;
            } else if (
                appInformation && 
                appInformation.actUsuario && 
                appInformation.actUsuario.active && 
                appInformation.isUpdateUser &&
                actualizarData && actualizarData.indActDatos === "2" ) {
                logger(`=================== useEffect.actUsuario =================`);   
                handlerVerifySession({accessToken, uid: userSelected.uid, token: hashedUser});
                return;
            } else if (appInformation['isAppMovil']) {       
                logger(`=================== useEffect.isAppMovil =================`);   
                target = `${alternativeUrlCallback}user=${email}&token=${hashedUser}&providerId=${providerId}&accessToken=${accessToken}${storageSessionId ? `&sessionID=${storageSessionId}` : ''}`;
                window.ReactNativeWebView?.postMessage(JSON.stringify(userSelected));
            } else {
                logger(`=================== useEffect.url_callback =================`);
                target = `${alternativeUrlCallback}user=${email}&token=${hashedUser}&providerId=${providerId}&accessToken=${accessToken}${storageSessionId ? `&sessionID=${storageSessionId}` : ''}`;
            }
            logger(`=================== useEffect.target ===================`);
            setCreateuser(false);
            logger(target);
            goToCallBack(target);
        }
    }, [userSelected, appInformation, createuser, redirect, refreshedToken, appId]);

    const handlerVerifySession = (evento) => {
        const { accessToken, uid, token } = evento;
        dispatch(verifyRefreshSession({ accessToken, uid, token }));
    };

    const goToCallBack = (target) => {
        window.location.href = target;
        return null;
    }

    const onSubmit = (evento) => {
        const { password, email } = evento;
        dispatch(cleanUser());
        setRedirect(false);
        setCreateuser(false);
        logger(`URL_CALLBACK_PARAM 1001 ${urlCallbackParam}`);
        logger(getUrlParam(appInformation.alternativeCallbacks, urlCallbackParam));
        logger(`getUrlParam.backparam ${urlCallbackParam} ${!isNonEmptyDigit(urlCallbackParam)}`);
        logger(`10.string ${urlCallbackParam} ${!isNonEmptyDigit("10")}`);
        logger(`10.numero ${urlCallbackParam} ${!isNonEmptyDigit(10)}`);
        dispatch(loginUser({ email, password, appInformation }));
    };

    const onSubmitEmpresas = (evento) => {
        const { password, email } = evento;
        const empresas = true;
        dispatch(loginUser({ email, password, appInformation, empresas }));
    };

    const onGoogleSubmit = () => {
        dispatch(cleanUser());
        setRedirect(false);
        setCreateuser(false);
        dispatch(loginWithGoogle({ provider: 'google' }));
    };

    const onAppleSubmit = () => {
        dispatch(cleanUser());
        setRedirect(false);
        setCreateuser(false);
        dispatch(loginWithGoogle({ provider: 'apple' }));
    };

    const onGoogleSubmitWithRedirect = () => {
        dispatch(cleanUser());
        setRedirect(false);
        setCreateuser(false);
        dispatch(loginWithRedirect({ provider: 'google' }));
    };

    const onAppleSubmitWithRedirect = () => {
        dispatch(cleanUser());
        setRedirect(false);
        setCreateuser(false);
        dispatch(loginWithRedirect({ provider: 'apple' }));
    };

    const closeModal = () => {
        logger(`closeModal`);
        dispatch(showModal(false));
    }

    const cancelRegister = () => {
        logger(`cancelRegister`);
        setRedirect(false);
    }

    const modalCofirmUpdate = () => {
        logger(`modalCofirmUpdate`);

        return (
            <div className={styles.modalWrapper}>
                <p className={styles.modalTextWrapper}>Sus datos de contacto se encuentran desactualizados</p>
                <p className={styles.modalTextWrapper}>¿Desea actualizarlo?</p>
                <div className={styles.modalButtons}>
                    <Button onClick={() => goToCallBack(actuser.urlUpdate)} className={styles.modalButton} variant="contained" color="success">Si</Button>
                    <Button onClick={() => goToCallBack(actuser.urlCallback)} className={styles.modalButton} variant="outlined" color="error">No</Button>
                </div>
            </div>
        )
    }

    return (
        <>
        { !loading && appInformation.modal && <CustomModal urlImagen={appInformation.urlImagenPopup} onCloseModal={closeModal} showModal={isModal} /> }
        { !loading && actuser && <CustomModal children={modalCofirmUpdate()} onCloseModal={closeModal} showModal={true} /> }
        <div className={`${styles.contenedor} ${global.background}`}>
            <div className={styles.container} id="container">
                <div className={`${styles.loadingContainer} ${loading ? styles.show : ''}`}>
                    <div className="progressContainer">
                        <p>Cargando información</p>
                        <LinearProgress className={styles.linearProgress} />
                    </div>
                </div>
                <div className={styles.loginCentrado}>
                    <div className="logo"></div>
                    <img className={global.logoImage} src='/logo-delagnte.png' alt="logo"/>
                    { userSelected.error 
                        && <Alert className={styles.alertError} severity="error">{ userSelected.error }</Alert>
                    }      
                    {
                        redirect && 
                            <div className={styles.alertWarning} >
                                <p>{redirect.message}</p>
                                {
                                    redirect.url &&                                 
                                    <div className={styles.modalButtons}>
                                        <Button onClick={() => goToCallBack(redirect.url)} className={styles.modalButton} variant="contained" color="success">Si</Button>
                                        <Button onClick={() => cancelRegister()} className={styles.modalButton} variant="outlined" color="error">No</Button>
                                    </div>
                                }
                            </div>
                    }
                    {
                        createuser && 
                            <div className={styles.alertWarning} >
                                <p>{createuser}</p>
                                <Countdown renderer={renderCountdown} className={styles.timerCountdown} date={Date.now() + 9000} />
                            </div>
                    }
                    { 
                        personas === 0 && 
                        <div className="personas-wrapper">
                            { createuser && <div className={styles.actualizarLayout}></div> }
                            <div className={styles.personalTitle} severity="success">Personas</div>
                            <Typography className={styles.titleLogin}>Inicia Sesión</Typography>
                            <div className={`${global.row} ${global.accordionRow}`}> 
                                <Accordion className={`${global.noShadow}`}>
                                <AccordionSummary 
                                        className={`${styles.btn} 
                                        ${global.personalIcon} 
                                        ${global.socialButton}  
                                        ${global.accordionContent}
                                        accordionWrapper
                                        `} aria-controls="panel1a-content" id="panel1a-header">
                                        <img className={global.comfeIcon} src={logoDelegante} width="24px" height="24px" alt="logo"/>
                                        <Typography className={styles.buttonTitle}>Cuenta Personalizada</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <UserForm onSubmit={onSubmit} submitLabel={`Iniciar Sesión`} pathname={pathname} passwordTooltip={toolTipPassword}/>
                                    </AccordionDetails>
                                    <div className={styles.center}> 
                                        <Link to='/reset' className={styles.registrarLink}>¿Olvidó su contraseña?</Link>
                                    </div>
                                </Accordion>
                            </div>
                            <Typography className={styles.titleLogin}>Inicia sesión con redes sociales</Typography>
                            {
                                appInformation && !appInformation.signWithRedirect &&
                                <>
                                    <div className={global.row}> 
                                        <Button onClick={() => onGoogleSubmit()}  variant="contained" className={`${styles.btn} ${global.googleIcon} ${global.socialButton}`}>
                                            <img className={global.icon} src={googleSVGIcon} alt="Google Icon button"></img>
                                            Iniciar Sesión con Google
                                        </Button>
                                    </div>
                                    {/* <div className={global.row}> 
                                        <Button onClick={() => onFacebookSubmit()}  variant="contained" className={`${styles.btn} ${global.facebookIcon} ${global.socialButton}`}>
                                            <FacebookIcon className={global.icon} />
                                            Iniciar Sesión con Facebook
                                        </Button>
                                    </div> */}
                                    <div className={global.row}> 
                                        <Button onClick={() => onAppleSubmit()}  variant="contained" className={`${styles.btn} ${global.appleIcon} ${global.socialButton}`}>
                                            <AppleIcon className={global.icon} />
                                            Iniciar Sesión con Apple
                                        </Button>
                                    </div>
                                </> 
                            }
                            {
                                appInformation && appInformation.signWithRedirect &&
                                <>
                                    <div className={global.row}> 
                                        <Button onClick={() => onGoogleSubmitWithRedirect()}  variant="contained" className={`${styles.btn} ${global.googleIcon} ${global.socialButton}`}>
                                            <img className={global.icon} src={googleSVGIcon} alt="Google Icon button"></img>
                                            Iniciar Sesión con Google
                                        </Button>
                                    </div>
                                    {/*
                                    <div className={global.row}> 
                                        <Button onClick={() => onFacebookSubmitWithRedirect()}  variant="contained" className={`${styles.btn} ${global.facebookIcon} ${global.socialButton}`}>
                                            <FacebookIcon className={global.icon} />
                                            Iniciar Sesión con Facebook
                                        </Button>
                                    </div>
                                    */}
                                    <div className={global.row}> 
                                        <Button onClick={() => onAppleSubmitWithRedirect()}  variant="contained" className={`${styles.btn} ${global.appleIcon} ${global.socialButton}`}>
                                            <AppleIcon className={global.icon} />
                                            Iniciar Sesión con Apple
                                        </Button>
                                    </div>
                                </>
                            }
                            <div className={global.separator}></div>
                            <div className={global.row}>
                                <Typography className={styles.registrarLink}>¿Olvidó su cuenta o quiere utilizar otra?</Typography>
                                <Typography className={styles.registrarLink}>
                                    Si desea ingresar por primera vez
                                    {
                                        appInformation ? <a className={styles.registrarLink} href={appInformation.urlRegister}>Regístrate aqui</a> :
                                        <Link to='/register' className={styles.registrarLink}> Regístrate aqui</Link>
                                    }
                                </Typography>
                            </div>
                        </div>
                    }
                    { 
                        personas === 1 &&
                        <div className="empresas-wrapper">
                            { createuser && <div className={styles.actualizarLayout}></div> }
                            <div className={styles.personalTitle} severity="success">Empresas</div>
                            <Typography className={styles.titleLogin}>Inicia Sesión</Typography>
                            <div className={`${global.row} ${global.accordionRow}`}> 
                                <Accordion className={`${global.noShadow}`} expanded={true}>
                                <AccordionSummary 
                                        className={`${styles.btn} 
                                        ${global.personalIcon} 
                                        ${global.socialButton}  
                                        ${global.accordionContent}
                                        accordionWrapper
                                        `} aria-controls="panel1a-content" id="panel1a-header">
                                        <img className={global.comfeIcon} src={logoDelegante} width="24px" height="24px" alt="logo"/>
                                        <Typography className={styles.buttonTitle}>Cuenta Personalizada</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <UserForm onSubmit={onSubmitEmpresas} submitLabel={`Iniciar Sesión`} pathname={pathname} passwordTooltip={toolTipPassword}/>
                                    </AccordionDetails>
                                    <div className={styles.center}> 
                                        <Link to='/reset' className={styles.registrarLink}>¿Olvidó su contraseña?</Link>
                                    </div>
                                </Accordion>
                            </div>
                        </div>
                    }
                </div>

            </div>
        </div>
        </>
    );
}

export default LoginComponent;
