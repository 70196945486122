export const firebaseErrorMessage = (errorCode) => {
    let errorMessage = '';

    switch (errorCode) {
        case 'auth/email-already-in-use': 
            errorMessage = `Este email ya está siendo usado: Usted tiene ya tiene una cuenta asociada al correo electrónico proporcionado. 
            Por favor comunicarse a la línea 602-8862727.`;            
            break;
        case 'auth/email-already-exists':
            errorMessage = `Este email ya existe: Usted tiene ya tiene una cuenta asociada al correo electrónico proporcionado. 
            Por favor comunicarse a la línea 602-8862727.`;             
            break;
        case 'auth/account-exists-with-different-credential':
            errorMessage = `Usted tiene ya tiene una cuenta asociada al correo electrónico proporcionado. 
            Por favor comunicarse a la línea 602-8862727.`;        
            break;

        case 'auth/invalid-password':
            errorMessage = `El valor que se proporcionó para la propiedad del usuario password 
            no es válido. Debe ser una string con al menos seis caracteres.`;            
            break;

        case 'auth/user-not-found':
            errorMessage = `No existe ningún registro de usuario con la combinación 
            email/password proporcionada`;            
            break;

        case 'auth/wrong-password':
            errorMessage = `La contraseña proporcionada es incorrecta, por favor verifica las credenciales.`;            
            break;  
            
        case 'auth/unverified-user':
            errorMessage = `El usuario no está verificado, por favor revise su bandeja correo electronico en la casilla de spam`;            
            break; 

        case 'auth/invalid-action-code':
            errorMessage = `El enlace de verificación ha expirado, por favor inicia el proceso nuevamente.`;       
            break; 

        case 'auth/popup-blocked':
            errorMessage = `Parece que los popups están bloqueados en su navegador. 
            Para poder continuar, por favor habilite los popups para este sitio web.`;            
            break;

        default:
            errorMessage = `Código de error ${errorCode} desconocido, por favor contacte con el administrador.`; 
            break;
    }

    return errorMessage;
}

export const CustomError = (msg = "", code = "") => {
    this.msg = msg;
    this.code = code;
}

export const emptyString = (msg) => {
    if (msg) return msg;

    return "";
}

export const getFromStorage = (key) => {
    const value = sessionStorage.getItem(key);

    if (value && typeof value === 'object') {
        return JSON.parse(value);
    } else if (value) {
        return value;
    }

    return false;
}

export const removeFromStorage = (key) => {
    sessionStorage.removeItem(key);
}

export const setToStorage = (key, value) => {
    if (value && typeof value === 'object') {
        sessionStorage.setItem(key, JSON.stringify(value));
    } else if (value) {
        sessionStorage.setItem(key, value);
    }
}

export const logger = (message, severity) => {
    if (process.env.REACT_APP_LOGGER === '1') {
        switch (severity) {
            case 'error':
                console.error(message);
            break;
    
            case 'info':
                console.log(message);            
            break;
                    
            case 'warning':
                console.warn(message);
            break;
        
            default:
                console.log(message);
            break;
        }
    }
}

export const getCoordinates = () => {
    return new Promise(function(resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });
}

export const getRamdomString = (length) => {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export const COMFE_ERROR_CODE = 'auth/wrong-comfe-info';

export const COMFE_RESET_MESSAGE = 'Ha solicitado un cambio de contraseña, por favor revise la bandeja de entrada del correo: ';

CustomError.prototype = Error.prototype;

export function isNonEmptyDigit(str) {
    // Check if the string is empty or null
    if (!str) {
        return false;
    }

    // Regular expression to match a single digit (0-9)
    const digitRegex = /^\d$/;

    // Return true if the string matches the regex (single digit)
    return digitRegex.test(str);
}

/*
Tu método de registro es Google con el siguiente usuario jve**@ava*********. Si no reconoce este usuario, por favor comunicarse a la línea 602-8862727
*/