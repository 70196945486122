import './App.css';
import { Route, Routes } from 'react-router-dom';
import LoginPage from './pages/Login';
import HomePage from './pages/Home';
import RegisterPage from './pages/Register';
import { store } from "./reducers/store";
import { Provider } from 'react-redux';
import LogoutPage from './pages/Logout';
import ResetPage from './pages/Reset';
import DeletePage from './pages/Delete';

function App() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return (
    <div className="App">
      <Provider store={store} >
        <Routes>
          <Route path='/' exact element={<HomePage />} />
          <Route path='/login' element={<LoginPage urlParams={urlParams} />} />
          <Route path='/register' element={<RegisterPage urlParams={urlParams} />} />
          <Route path='/logout' element={<LogoutPage urlParams={urlParams} />} />
          <Route path='/reset' element={<ResetPage urlParams={urlParams} />} />
          <Route path='/delete' element={<DeletePage urlParams={urlParams} />} />
        </Routes>
      </Provider>
    </div>
  );
}

export default App;
