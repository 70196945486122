import React from "react";
import LoginComponent from "./component";


function LoginPage(props) {
    const { urlParams } = props;

    return ( 
        <LoginComponent urlParams={urlParams} />
    );
}

export default LoginPage;