import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import UserForm from "../../components/UserForm/UserForm";
import { checkAppInfo, loginSingleSignOn, loginWithGoogle, signOutUser, signUpUser } from "../../reducers/actions";
import { selectApplication, selectLoginLoading, selectLoginSource } from "../../reducers/reducer";
import { useLoginSelector } from "../../reducers/store";
import styles from "./styles.module.css";
import "./styles.css";
import global from "../../app.module.css";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Typography } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import { Link, Navigate, useLocation } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import googleSVGIcon from '../../images/logoGoogle.svg';
import logoDelegante from '../../images/logo-delegante.png';
import { getFromStorage, logger, setToStorage } from "../../reducers/utilities";

function RegisterComponent(props) {
    const { urlParams } = props;
    const appId = urlParams.get('app_id');
    const tipoDoc = urlParams.get('tipoDoc');
    const tipoPersona = urlParams.get('tipoPersona');
    const numDoc = urlParams.get('numDoc');
    const urlMovil = urlParams.get('urlMovil');
    const appIdOrigen = urlParams.get('app_id_origen');

    const dispatch = useDispatch();
    const location = useLocation();
    const pathname = location.pathname;
    const loading = useLoginSelector(selectLoginLoading);
    const appInformation = useLoginSelector(selectApplication);
    const userSelected = useLoginSelector(selectLoginSource);

    const toolTipPassword = <div id="m_pswd_info" className={styles.m_pswd_info}>
                    <div>
                        <span id="m_length" className="m_invalid"><CancelIcon className={styles.closeIcon}/> (Obligatorio) Longitud mínimo de 8 carácteres.</span><br />
                        <span id="m_opc" className="m_invalid"> <CancelIcon className={styles.closeIcon}/> Contener al menos 3 de los siguientes 4 tipos de caracteres: </span>
                        <ul>
                            <li id="m_letter" className="m_invalid"> Mínimo una letra minúscula.</li>
                            <li id="m_capital" className="m_invalid">Mínimo una letra mayúscula.</li>
                            <li id="m_number" className="m_invalid">Mínimo un número (0-9).</li>
                            <li id="m_special" className="m_invalid">Mínimo un carácter especial (!@#$%^&amp;*).</li>
                        </ul>
                    </div>
                </div>;

    useEffect(() => {
        logger(`Environment: ${process.env.REACT_APP_NODE_ENV}`);
        logger(`Environment: ${process.env.REACT_APP_COMFENALCO_AUTHDOMAIN}`);
        const isSignInWithRedirect = getFromStorage('signInWithRedirect'); 

        if(!isSignInWithRedirect) {
            dispatch(signOutUser());
        }

        dispatch(checkAppInfo(appId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (appInformation && userSelected && userSelected.uid) {
            dispatch(loginSingleSignOn({appInformation, numDoc, tipoDoc, tipoPersona, pathname, userSelected}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, appInformation, pathname, userSelected]);

    useEffect(() => {
        const isSignInWithRedirect = getFromStorage('signInWithRedirect');
        logger(`isSignInWithRedirect ${isSignInWithRedirect}`);

        if (userSelected && 
            userSelected.error && 
            userSelected.code === 'auth/email-already-in-use') {
                logger(`Error => user used`);
        }

        if (userSelected && userSelected.hashedUser && userSelected.uid && appInformation) {
            setToStorage('signInWithRedirect', false);
            const { email, hashedUser, uid, providerId, accessToken } = userSelected;
            let target = false;
            
            if (appInformation['isAppMovil']) {
                target = `${urlMovil}?user=${email}&token=${hashedUser}&tipoDoc=${tipoDoc}&userType=${tipoPersona}&numDoc=${numDoc}&uid=${uid}&providerId=${providerId}`;
                window.ReactNativeWebView?.postMessage(JSON.stringify(userSelected));
            } else {
                target = `${appInformation['url_callback']}?user=${email}&token=${hashedUser}&tipoDoc=${tipoDoc}&userType=${tipoPersona}&numDoc=${numDoc}&uid=${uid}&providerId=${providerId}&accessToken=${accessToken}${appIdOrigen ? `&app_id_origen=${appIdOrigen}` : ''}`;
            }
            
            goToCallBack(target);
        }
    }, [userSelected]);


    const onSubmit = (evento) => {
        evento.numDoc=numDoc;
        evento.tipoDoc=tipoDoc; 
        evento.tipoPersona=tipoPersona;
        evento.appInformation=appInformation;
        dispatch(signUpUser(evento));
    };
    
    const onGoogleSubmit = () => {
        dispatch(loginWithGoogle({ provider: 'google', pathname }));
    };

    const onFacebookSubmit = () => {
        dispatch(loginWithGoogle({ provider: 'facebook', pathname }));
    };

    const onAppleSubmit = () => {
        dispatch(loginWithGoogle({ provider: 'apple', pathname }));
    };

    function goToCallBack(target) {
        // 👇️ using window.location.href 👇️
        window.location.href = target;
        return null;
    }

    return (
        <>
        <div className={`${styles.contenedor} ${global.background}`}>
            <div className={styles.container} id="container">
                <div className={`${styles.loadingContainer} ${loading ? styles.show : ''}`}>
                    <p>Loading...</p>
                </div>
                <div className={styles.loginCentrado}>
                    <div className="logo"></div>
                    <img className={global.logoImage} src='/logo-delagnte.png' alt="logo"/>
                    <div className={styles.personalTitle} severity="success">Personas</div>
                    <div>
                        { userSelected.error 
                            && <Alert className={styles.alertError} severity="error">{ userSelected.error }</Alert>
                        } 
                        {
                            userSelected.code === 'auth/email-already-in-use' ? 
                            <Alert className={styles.alertError} severity="error">
                                O puedes remover el usuario <Link target='_blank' to={`/delete?app_id=${appId}`}>aquí</Link>
                            </Alert> : ''
                        }     
                    </div>
                    <Typography className={styles.titleLogin}>Registrar Usuario</Typography>
                    <div className={`${global.accordionRow} ${global.row}`}> 
                        <Accordion className={`${global.noShadow}`}>
                            <AccordionSummary 
                                className={`${styles.btn} 
                                ${global.personalIcon} 
                                ${global.socialButton}  
                                ${global.accordionContent} 
                                accordionWrapper
                                `} aria-controls="panel1a-content" id="panel1a-header">
                                <img className={global.comfeIcon} src={logoDelegante} width="24px" height="24px" alt="logo"/>
                                <Typography className={styles.buttonTitle}>Cuenta Personalizada</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <UserForm onSubmit={onSubmit} submitLabel={`Crear usuario`} pathname={pathname} passwordTooltip={toolTipPassword}/>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <Typography className={styles.titleLogin}>Inicia sesión con redes sociales</Typography>
                    <div className={global.row}> 
                        <Button onClick={() => onGoogleSubmit()}  variant="contained" className={`${styles.btn} ${global.googleIcon} ${global.socialButton}`}>
                            <img className={global.icon} src={googleSVGIcon} alt="Google Icon button"></img>
                            Iniciar Sesión con Google
                        </Button>
                    </div>
                    {/*
                    <div className={global.row}> 
                        <Button onClick={() => onFacebookSubmit()}  variant="contained" className={`${styles.btn} ${global.facebookIcon} ${global.socialButton}`}>
                            <FacebookIcon className={global.icon} />
                            Iniciar Sesión con Facebook
                        </Button>
                    </div>
                    */}
                    <div className={global.row}> 
                        <Button onClick={() => onAppleSubmit()}  variant="contained" className={`${styles.btn} ${global.appleIcon} ${global.socialButton}`}>
                            <AppleIcon className={global.icon} />
                            Iniciar Sesión con Apple
                        </Button>
                    </div>
                    <div className={global.separator}></div>
                </div>
            </div>
        </div>
        </>
    );
}

export default RegisterComponent;
