import React from "react";
import DeleteComponent from "./component";


function DeletePage(props) {
    const { urlParams } = props;

    return ( 
        <DeleteComponent urlParams={urlParams} />
    );
}

export default DeletePage;