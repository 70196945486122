import React from "react";
import RegisterComponent from "./component";

function RegisterPage(props) {
    const { urlParams } = props;

    return ( 
        <RegisterComponent urlParams={urlParams} />
    );
}

export default RegisterPage;