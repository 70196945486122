
import { Provider } from "react-redux";
import { store } from "./reducers/store";
import HomeComponent from "./component";

function HomePage() { 
    return ( 
        <Provider store={store}>
            <HomeComponent/>
        </Provider>
    );
}

export default HomePage;