import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import styles from "./styles.module.css";
import { useEffect } from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
            <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
        ) : null}
        </DialogTitle>
    );
}

export default function CustomModal(props) {
    const { showModal, onCloseModal, urlImagen, children } = props;
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setOpen(showModal);
    }, [showModal]);

    const handleClose = () => {
        onCloseModal();
        setOpen(false);
    };

    return (
        <div> 
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className={styles.customModal}
            >
                <BootstrapDialogTitle className={styles.actionHeader} id="customized-dialog-title" onClose={handleClose}>
                </BootstrapDialogTitle>
                <DialogContent>
                    { urlImagen && <img className={global.logoImage} src={urlImagen ? urlImagen : '/plataforma-web.jpg'} alt="logo"/> }
                    { children && children }
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}