import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = process.env.REACT_APP_RICK_MORTY;

export const checkHomeSource = createAsyncThunk(
    "home/checkHomeSource",
    async (post) => {
        const response = await axios(url);
        let source = false;

        if (response) {
            const { data: { results } } = response;
            source = results;
        }

        return source;
    }
);

export const selectPost = createAction("home/selectData");