// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_COMFENALCO_APIKEY,
    authDomain: process.env.REACT_APP_COMFENALCO_AUTHDOMAIN,
    projectId: process.env.REACT_APP_COMFENALCO_PROJECTID,
    storageBucket: process.env.REACT_APP_COMFENALCO_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_COMFENALCO_MESSAGING_SENDERID,
    appId: process.env.REACT_APP_COMFENALCO_APPID,
    measurementId: process.env.REACT_APP_COMFENALCO_MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);